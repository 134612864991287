import axios from "@/core/services";
const state = {
  auditList: [],
  handEmpList: [],
  expenseTypeList: [],
  erpCompanyList: [],
  supplychainComList: [],
  customerList: [],
  saleCompanyList:[],
  organizationAuditList:[],
  categoryAuditList:[],
  bankAuditList:[],
};
const mutations = {
  getAuditList(state, args) {
    axios
      .fetchPost("kybAudit/getAuditList", args)
      .then(data => {
        if (data && data.data) {
          state.auditList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getAuditHandOver(state, args) {
    axios
      .fetchPost("kybOa/getAuditHandOver", args)
      .then(data => {
        if (data && data.data) {
          state.handEmpList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getExpenseTypeList(state) {
    axios
      .fetchPost("kybOa/getExpenseTypeList")
      .then(data => {
        if (data && data.data) {
          state.expenseTypeList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getSupplychainApplyData(state) {
    axios
        .fetchPost("kybOa/getSupplychainApplyData")
        .then(data => {
          state.erpCompanyList = [];
          state.supplychainList = [];
          state.customerList = [];
          if (data && data.data) {
            state.customerList = data.data.customerList;
            if (
                data.data.groupCompanyList &&
                data.data.groupCompanyList.length > 0
            ) {
              for (let groupCompany of data.data.groupCompanyList) {
                if (groupCompany.supplyFlag) {
                  state.supplychainComList.push(groupCompany);
                } else {
                  state.erpCompanyList.push(groupCompany);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
  },
    getSaleCompanyList(state) {
      axios
          .fetchPost("kybOa/getSaleCompanyList")
          .then(data => {
            state.saleCustomerList = [];
            if (data && data.data) {
              state.saleCompanyList = data.data;
            }
          })
          .catch(err => {
            console.log(err);
          });
    },

    getOrganizationAuditList(state) {
        axios
            .fetchPost("kybOa/getOrganizationAuditList")
            .then(data => {
                state.organizationAuditList = [];
                if (data && data.data) {
                    state.organizationAuditList = data.data;
                }
            })
            .catch(err => {
                console.log(err);
            });
    },
    getCategoryAuditList(state) {
        axios
            .fetchPost("kybOa/getCategoryAuditList")
            .then(data => {
                state.categoryAuditList = [];
                if (data && data.data) {
                    state.categoryAuditList = data.data;
                }
            })
            .catch(err => {
                console.log(err);
            });
    },

    getBankNameList(state,arg){
      const query = {};
      query.recordId = arg;
        axios.fetchPost("kybOa/getAbstractList",query).then(data => {
            state.bankAuditList = [];
            if (data && data.data) {
                state.bankAuditList = data.data;
            }
        }).catch(err => {
                console.log(err);
            });
    }
};
const actions = {
  getAuditList(context, args) {
    context.commit("getAuditList", args);
  },
  getAuditHandOver(context, args) {
    context.commit("getAuditHandOver", args);
  },
  getExpenseTypeList(context) {
    context.commit("getExpenseTypeList");
  },
  getSupplychainApplyData(context) {
    context.commit("getSupplychainApplyData");
  },
    getSaleCompanyList(context) {
    context.commit("getSaleCompanyList");
    },
    getOrganizationAuditList(context) {
        context.commit("getOrganizationAuditList");
    },
    getCategoryAuditList(context) {
        context.commit("getCategoryAuditList");
    },
    getBankNameList(context, args) {
        context.commit("getBankNameList", args);
    }

};
const getters = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
